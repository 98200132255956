import React from 'react';
import styles from './credits.module.scss';

// import tietoa from '../assets/logos/tietoa-credit.png';

const Credits = () => (
  <div className={styles.Credits}>
    {/* <div className={styles.Logo}>
        <a target="_blank" rel="noopener noreferrer" href="https://tietoa.com/visualisointi/weup-air-map/"><img src={tietoa} alt="Tietoa"/></a>
      </div> */}
    <div className={styles.Block}>GIS data: City of Tampere, NLS, FTIA</div>
  </div>
);

export default Credits;
