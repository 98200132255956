import { createStore, applyMiddleware, compose } from "redux";
import thunk from "redux-thunk";
import reducers from "./reducers";

let composed = compose(applyMiddleware(thunk));
if (window.__REDUX_DEVTOOLS_EXTENSION__) {
  composed = compose(
    applyMiddleware(thunk),
    window.__REDUX_DEVTOOLS_EXTENSION__ && window.__REDUX_DEVTOOLS_EXTENSION__() // Comment this line to disable Chrome Redux extension.
  );
}

export default createStore(reducers, composed);
