//var baseUri = "https://cesium-3d-tiles.storage.googleapis.com/icons/";
var hotspotBaseUri = "/icons/hotspots/";

export const typesIcon = (icon) => {
  if (!icon) {
    return hotspotBaseUri + "placeholder.svg";
  } else {
    return hotspotBaseUri + icon;
  }
};
