import React, { Component } from 'react';
import { connect } from 'react-redux';
import cookie from 'react-cookies';
import 'react-confirm-alert/src/react-confirm-alert.css';
import './App.css';
// import Categories from './Categories';
// import SubCategoryDetails from './SubCategoryDetails';
import Hotspots from './Hotspots';
import WelcomeMessage from './WelcomeMessage';
import Credits from './Credits';
import ViewOptionsPanel from './components/ViewOptionsPanel';
import { getSelectedLanguage } from './i18n';
import TampereLogo from './assets/logos/logo-tampere.svg';
import TampereLogo2 from './assets/logos/tampere-finland.png';
import HomeIcon from './assets/icons/home.svg';
import './lib/firebase';

const cesiumAssets = window.cesiumAssets;

class App extends Component {
  state = {
    firstTimeRunning: false,
  };

  componentDidMount() {
    const firstTimeRunning = cookie.load('firstTime');

    if (!firstTimeRunning) {
      cookie.save('firstTime', 'firstTime');
      this.setState({ firstTimeRunning: true });
    }

    // Language
    const currentLanguage = getSelectedLanguage();

    window.localStorage.setItem(
      'selectedLanguage',
      JSON.stringify(currentLanguage)
    );
  }

  closeWelcomeMessage = () => {
    this.setState({ firstTimeRunning: false });
  };

  render() {
    return (
      <div className="horizontalCoordinates">
        {this.props.portableState.categories && (
          <div>
            <ViewOptionsPanel cesiumAssets={cesiumAssets} />
            {/* <SubCategoryDetails /> */}
            {/* <Categories /> */}
            <Hotspots />
            <Credits />
            <WelcomeMessage
              closeWelcomeMessage={this.closeWelcomeMessage}
              show={this.state.firstTimeRunning}
            />
            <Logo />
            <Home />
            <FooterLogo />
          </div>
        )}

        <div />
      </div>
    );
  }
}

function mapStateToProps(state) {
  return {
    portableState: state.portableState,
  };
}

export default connect(mapStateToProps, null)(App);

function Logo() {
  return (
    <div
      style={{
        position: 'absolute',
        top: 20,
        left: 20,
        width: 180,
        zIndex: 10,
      }}
    >
      <img
        src={TampereLogo}
        alt="Tampere logo"
        style={{ width: '100%', height: '100%' }}
      />
    </div>
  );
}

function FooterLogo() {
  return (
    <div
      style={{
        position: 'absolute',
        bottom: -5,
        right: 15,
        width: 250,
        zIndex: 10,
      }}
    >
      <img
        src={TampereLogo2}
        alt="Tampere logo"
        style={{ width: '100%', height: '100%' }}
      />
    </div>
  );
}

function Home() {
  const [krpano, setKrpano] = React.useState(null);

  const krpano_onready_callback = React.useCallback((krpano_interface) => {
    setKrpano(krpano_interface);
  }, []);

  React.useEffect(() => {
    const initKrpano = () => {
      window.embedpano({
        id: 'krpanoSWFObject',
        xml: 'indexdata/index.xml',
        target: 'panoDIV',
        bgcolor: '#FFFFFF',
        consolelog: false, // trace krpano messages also to the browser console
        passQueryParameters: true, // pass query parameters of the url to krpano
        onready: krpano_onready_callback,
      });
    };
    initKrpano();
  }, [krpano_onready_callback]);

  return (
    <div
      style={{
        position: 'absolute',
        top: 30,
        right: 20,
        zIndex: 10,
        cursor: 'pointer',
      }}
      onClick={() =>
        krpano.call(`loadscene(pano211, null, MERGE, BLEND(0.5, easeInCubic));`)
      }
    >
      <img
        src={HomeIcon}
        alt="Home"
        style={{ width: '30px', height: '30px' }}
      />
    </div>
  );
}
