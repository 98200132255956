import React from "react";
import classNames from "classnames";
import styles from "./hotspot.module.scss";
import { typesIcon } from "../types_icon";

const Hotspot = ({
  coordinates,
  data,
  chosenSubCategory,
  subCategoryHover,
  onChoseSubCategory,
  onHover,
  unHover,
  rotateDone
}) => {
  const isHot =
    data.name === subCategoryHover ||
    (data.id === chosenSubCategory && !subCategoryHover);
  return (
    <div
      className={classNames(styles.Hotspot, {
        [styles.Hotspot_shine]: data.name === rotateDone
      })}
      style={{
        top: coordinates.top - 85 + "px",
        left: coordinates.left - 11 + "px",
        visibility: true
      }}>
      <div
        className={styles.Hotspot_details}
        onClick={() => onChoseSubCategory(data)}
        onMouseEnter={() => onHover(data.name)}
        onMouseLeave={() => unHover(data.name)}>
        <img
          src={typesIcon(data.icon)}
          alt="placeholder"
          className={styles.Hotspot_icon}
        />
        <div
          className={
            isHot ? styles.Hotspot_title_on : styles.Hotspot_title_off
          }>
          {data.name}
        </div>
      </div>
      <div className={styles.Hotspot_line} />
    </div>
  );
};

export default Hotspot;
