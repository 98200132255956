import * as constants from '../constants/portableState';
import store from '../store';
import jsurl from 'jsurl';

export function updateView() {
  // TODO: Remove stupid hack
  if (window.assetsLoaded) {
    const { portableState } = store.getState();
    window.sendNewState(portableState);
  }
}

//@TODO: Make sure this function is called. If not : Remove
export function setHotspotCoordinates(payload) {
  return (dispatch) => {
    dispatch({
      type: constants.SET_HOTSPOT_COORDINATES,
      payload,
    });
  };
}
export function filterJsonPortableState(portableState) {
  const fieldsToSerializeToJSON = [
    'activeCategory',
    'activeSubcategory',
    'categories',
  ];
  const jsonState = {};

  // Pick only the fields that we want to serialize.
  fieldsToSerializeToJSON.forEach((field) => {
    jsonState[field] = portableState[field];
  });

  return (
    'data:text/json;charset=utf-8,' +
    encodeURIComponent(JSON.stringify(jsonState, null, 2))
  );
}

export function hashToState(hash) {
  const parsedState = jsurl.parse(hash.substr(1), null);
  return parsedState;
}

export function stateToHash(portableState) {
  const fieldsToSerializeToURI = [
    'activeCategory',
    'activeSubcategory',
    'view',
    'time',
    'scene',
  ];

  const uriState = {};
  // Pick only the fields that we want to serialize.
  fieldsToSerializeToURI.forEach((field) => {
    uriState[field] = portableState[field];
  });
  return '#' + jsurl.stringify(uriState);
}

export function syncLocationHash() {
  const { portableState, notifications } = store.getState();

  if (notifications.dev_mode) {
    window.location.hash = stateToHash(portableState);
  } else {
    window.location.hash = '#';
  }
}

export function setSelectedSubcategory(payload) {
  //"4gq4x3q1m"
  return (dispatch) => {
    dispatch({
      type: constants.SUB_CATEGORY_CHOSEN,
      payload,
    });
    updateView();
    syncLocationHash();
  };
}

export function setSelectedCategory(payload) {
  return (dispatch) => {
    dispatch({
      type: constants.CATEGORY_CHOSEN,
      payload,
    });
    updateView();
    syncLocationHash();
  };
}

export function setAssetVisibility(payload) {
  return (dispatch) => {
    dispatch({
      type: constants.ASSET_VISIBILITY_SET,
      payload,
    });
    updateView();
    syncLocationHash();
  };
}

export function setActiveAsset(payload) {
  return (dispatch) => {
    dispatch({
      type: constants.ACTIVE_ASSET_SET,
      payload,
    });
    updateView();
    syncLocationHash();
  };
}

export function addCategory(payload) {
  return (dispatch) => {
    dispatch({
      type: constants.ADD_CATEGORY,
      payload,
    });
  };
}

export function patchCategory(payload) {
  return (dispatch) => {
    dispatch({
      type: constants.PATCH_CATEGORY,
      payload,
    });
    syncLocationHash();
  };
}

export function removeCategory(payload) {
  const { portableState } = store.getState();
  if (portableState.activeCategory === payload.id) {
    setSelectedCategory(null);
  }

  return (dispatch) => {
    dispatch({
      type: constants.REMOVE_CATEGORY,
      payload,
    });
    updateView();
    syncLocationHash();
  };
}

export function addSubCategory(payload) {
  return (dispatch) => {
    dispatch({
      type: constants.ADD_SUB_CATEGORY,
      payload,
    });
    updateView();
    syncLocationHash();
  };
}

export function patchSubCategory(payload) {
  return (dispatch) => {
    dispatch({
      type: constants.PATCH_SUB_CATEGORY,
      payload,
    });
    syncLocationHash();
  };
}

export function removeSubCategory(payload) {
  const { portableState } = store.getState();
  payload.activeCategory = portableState.activeCategory;
  return (dispatch) => {
    dispatch({
      type: constants.REMOVE_SUB_CATEGORY,
      payload,
    });
    updateView();
    syncLocationHash();
  };
}
