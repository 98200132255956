import * as constants from "../constants/notifications";
import { syncLocationHash } from "./portableState";

export function onHoverSubCategory(v) {
  return (dispatch) => {
    dispatch({
      type: constants.SUB_CATEGORY_HOVER,
      payload: v
    });
  };
}

export function unHoverSubCategory() {
  return (dispatch) => {
    dispatch({
      type: constants.SUB_CATEGORY_UN_HOVER
    });
  };
}

export function enableDevMode(v) {
  return (dispatch) => {
    dispatch({
      type: constants.DEV_MODE,
      payload: v
    });
    if (!v) {
      window.location.hash = "";
    } else {
      syncLocationHash();
    }
  };
}
