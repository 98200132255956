import React from 'react';
import classNames from 'classnames';
import { useTranslation } from 'react-i18next';

import close from '../assets/icons/close.svg';
import Rotation from '../assets/icons/3d_rotation.svg';
import Sphere from '../assets/icons/baseline-trip_origin-24px.svg';
import Home from '../assets/icons/home.svg';
import styles from './welcome_message.module.scss';
import { getSelectedLanguage } from '../i18n';

const WelcomeMessage = ({ message, closeWelcomeMessage, show }) => {
  const { t, i18n } = useTranslation('translation');
  const [selectedLanguage, setSelectedLanguage] = React.useState('fi');

  React.useEffect(() => {
    const currentLanguage = getSelectedLanguage();
    setSelectedLanguage(currentLanguage);
  }, []);

  const handleToggleLanguage = () => {
    const language = selectedLanguage === 'fi' ? 'en' : 'fi';
    setSelectedLanguage(language);
    i18n.changeLanguage(language);
    window.localStorage.setItem('selectedLanguage', JSON.stringify(language));
  };

  return (
    <div
      className={classNames(styles.WelcomeMessage, { [styles.hide]: !show })}
    >
      <div className={styles.WelcomeMessage_box}>
        <div className={styles.WelcomeMessage_header}>
          <h4>{t('welcome-title')}</h4>
          <img onClick={closeWelcomeMessage} src={close} alt="close" />
        </div>
        <div className={styles.WelcomeMessage_body}>
          <div className={styles.message_text}></div>
          <img src={Rotation} alt="Rotation" />
          <h4>{t('welcome-message-1')}</h4>
        </div>
        <div className={styles.WelcomeMessage_body}>
          <div className={styles.message_text}></div>
          <img src={Sphere} alt="Rotation" />
          <h4>{t('welcome-message-2')}</h4>
        </div>
        <div className={styles.WelcomeMessage_body}>
          <div className={styles.message_text}></div>
          <img src={Home} alt="Home" />
          <h4>{t('welcome-message-5')}</h4>
        </div>
        <div className={styles.WelcomeMessage_footer}>
          <h5 className={styles.WelcomeMessage_footer__message}>
            <p>{t('welcome-message-3')}</p>
            <p>
              {t('welcome-message-4')} {t('welcome-message-6')}
            </p>
          </h5>
          <button onClick={closeWelcomeMessage}>{t('btn-got-it')}</button>
        </div>
        <button className={styles.language_btn} onClick={handleToggleLanguage}>
          <span>
            Change to {selectedLanguage === 'fi' ? 'English' : 'Finnish'}
          </span>
        </button>
      </div>
    </div>
  );
};

export default WelcomeMessage;
