import * as constants from "../constants/portableState";
import { hashToState, stateToHash } from "../actions/portableState";

const initialJsonState = require("../../Tietoa_weup.json");
const initialState = Object.assign(initialJsonState);

const getNewId = () => {
  return Math.random()
    .toString(36)
    .substr(2, 9);
};

export default function portableState(state = initialState, action = {}) {
  var id,
    categoryId,
    activeCategoryId,
    activeSubcategoryId,
    assetName,
    categories,
    category,
    patchData,
    parentCategory,
    subcategory;

  let finalState = null;

  switch (action.type) {
    case constants.NEW_HASH:
      // We need to check that hash state is actually a new one so we don't end up in an infinite loop.'
      // Only comparing hash as string
      const windowHash = window.location.hash;

      const stateFromHash = hashToState(windowHash);
      const stateHash = stateToHash(state);

      const isNew = stateHash !== windowHash;
      finalState = {
        ...state,
        ...(isNew ? stateFromHash : {})
      };
      break;

    case constants.SET_STATE_FROM_CLOUD:
      finalState = {
        //@TODO: Consider reading the hash state only after this
        ...action.payload
      };
      break;

    case constants.SET_HOTSPOT_COORDINATES:
      finalState = {
        ...state,
        screenCoordinates: action.payload
      };
      break;

    case constants.SET_KRPANO_COORDINATES:
      finalState = {
        ...state,
        view: action.payload
      };
      break;

    case constants.SET_KRPANO_SCENE:
      finalState = {
        ...state,
        scene: action.payload
      };
      break;

    case constants.SET_CESIUM_TIME:
      finalState = {
        ...state,
        time: action.payload
      };
      break;

    case constants.CATEGORY_CHOSEN:
      finalState = {
        ...state,
        activeCategory: action.payload
      };
      break;

    case constants.SUB_CATEGORY_CHOSEN:
      finalState = {
        ...state,
        activeSubcategory: action.payload
      };
      break;

    case constants.ASSET_VISIBILITY_SET:
      if (!state.activeSubcategory) {
        throw new Error(
          "Attempted to toggle visibility without selected SubCat."
        );
      }
      // console.log("ACTION", action);
      activeCategoryId = action.payload.category;
      activeSubcategoryId = state.activeSubcategory;
      assetName = action.payload.name;
      const visibility = action.payload.visibility;
      const parentAssetName = action.payload.parentAssetName;

      categories = state.categories;
      if (parentAssetName) {
        // Create parent asset property if it does not exist.
        categories[activeCategoryId].subcategories[activeSubcategoryId].assets[
          parentAssetName
        ] = categories[activeCategoryId].subcategories[activeSubcategoryId]
          .assets[parentAssetName] || {
          name: parentAssetName,
          visibility: false,
          children: {}
        };

        // Get a handle on the parent asset.
        const parentAsset =
          categories[activeCategoryId].subcategories[activeSubcategoryId]
            .assets[parentAssetName];

        parentAsset.children[assetName] = {
          visibility,
          name: assetName
        };
      } else {
        // Get children assets for toggling them with parent.
        const cesiumAssets = window.cesiumAssets.assets;
        const cesiumChildren = cesiumAssets[assetName].children || {};
        const defaultChildren = {};
        Object.keys(cesiumChildren).forEach(childName => {
          defaultChildren[childName] = { visibility };
        });

        // Create asset if it does not exists.
        categories[activeCategoryId].subcategories[activeSubcategoryId].assets[
          assetName
        ] = categories[activeCategoryId].subcategories[activeSubcategoryId]
          .assets[assetName] || {
          name: assetName
        };
        // Toggle children along parent asset.
        categories[activeCategoryId].subcategories[activeSubcategoryId].assets[
          assetName
        ].children = defaultChildren;

        categories[activeCategoryId].subcategories[activeSubcategoryId].assets[
          assetName
        ].visibility = visibility;
      }
      finalState = {
        ...state,
        categories
      };
      break;

    case constants.ACTIVE_ASSET_SET:
      if (!state.activeSubcategory) {
        throw new Error(
          "Attempted to set active asset without selected SubCat."
        );
      }

      activeSubcategoryId = state.activeSubcategory;
      activeCategoryId = action.payload.category;
      assetName = action.payload.activeAssetName;

      categories = state.categories;

      categories[activeCategoryId].subcategories[
        activeSubcategoryId
      ].activeAsset = assetName;
     
      categories = state.categories;

      finalState = {
        ...state,
        categories
      };
      break;

    case constants.ADD_CATEGORY:
      categories = state.categories;
      category = action.payload;
      category.id = getNewId();
      categories[category.id] = category;

      finalState = {
        ...state,
        activeSubcategory: null,
        categories
      };
      break;
    case constants.PATCH_CATEGORY:
      categories = state.categories;
      patchData = action.payload.category;
      const existingCategory = categories[patchData.id];
      categories = state.categories;
      categories[patchData.id] = {
        ...existingCategory,
        ...patchData
      };

      finalState = {
        ...state,
        categories
      };
      break;

    case constants.REMOVE_CATEGORY:
      categories = state.categories;
      id = action.payload.id;
      delete categories[id];

      finalState = {
        ...state,
        categories,
        activeCategory: null,
        activeSubcategory: null
      };
      break;

    case constants.ADD_SUB_CATEGORY:
      const subcategoryTemplate = {
        id: getNewId(),
        name: null,
        type: null,
        data: [],
        activeAsset: null,
        assets: {}
      };

      categories = state.categories;
      categoryId = action.payload.categoryId;
      subcategory = { ...subcategoryTemplate, ...action.payload.subcategory };

      categories[categoryId].subcategories[subcategory.id] = subcategory;

      finalState = {
        ...state,
        categories
      };
      break;

    case constants.PATCH_SUB_CATEGORY:
      categories = state.categories;
      patchData = action.payload;

      parentCategory = Object.values(categories).find(c => {
        return c.subcategories[patchData.id] !== undefined;
      });
      categoryId = parentCategory.id;
      const existingSubCategory =
        categories[categoryId].subcategories[patchData.id];
      const patchedSubCategory = { ...existingSubCategory, ...patchData };
      categories[categoryId].subcategories[patchData.id] = patchedSubCategory;

      finalState = {
        ...state,
        categories
      };
      break;

    case constants.REMOVE_SUB_CATEGORY:
      categories = state.categories;
      id = action.payload.id;

      parentCategory = Object.values(categories).find(c => {
        return c.subcategories[id] !== undefined;
      });

      delete categories[parentCategory.id].subcategories[id];

      finalState = {
        ...state,
        categories
      };
      break;

    default:
      finalState = state;
      break;
  }
  return finalState;
}
