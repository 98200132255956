import React, { Component } from 'react';
import { connect } from 'react-redux';
import classNames from 'classnames';

import styles from './ViewOptionsPanel.module.scss'; // Copied from the Categories component. Lots of unused styles.
import expandLess from '../assets/icons/expand_less.svg';
import expandMore from '../assets/icons/expand_more.svg';

import ViewOptionsRow from './ViewOptionsRow';
import {
  setAssetVisibility,
  setActiveAsset,
} from '../redux/actions/portableState';

class ViewOptions extends Component {
  constructor(props) {
    super(props);
    this.state = {
      showPanel: true,
      activeAsset: null,
    };
  }

  componentDidMount() {}

  componentWillUnmount() {}

  togglePanel = () => {
    this.setState({ showPanel: !this.state.showPanel });
  };

  setActiveAsset = (item, parentAssetName) => {
    this.props.setActiveAsset({
      activeAssetName: item ? item.name : '',
      subcategory: this.props.activeSubcategory,
      category: this.props.chosenCategory,
      parentAssetName,
    });
  };

  toggleVisible = (assetState, parentAssetName) => {
    this.props.setAssetVisibility({
      subcategory: this.props.activeSubcategory,
      category: this.props.chosenCategory,
      name: assetState.name,
      visibility: !assetState.visibility,
      parentAssetName,
    });
    this.forceUpdate();
  };

  render() {
    const cesiumAssets = window.cesiumAssets;
    const {
      chosenCategory,
      activeSubcategory,
      portableState,
      dev_mode,
    } = this.props;
    const subcategory =
      chosenCategory && activeSubcategory
        ? portableState.categories[chosenCategory].subcategories[
            activeSubcategory
          ]
        : {};

    const { showPanel } = this.state;
    return dev_mode ? (
      <div className={classNames(styles.ViewOptions)}>
        <div className={styles.viewOptions_header}>
          <h5 className={styles.viewOptions_header_title}>View Options</h5>
          <img
            onClick={this.togglePanel}
            src={showPanel ? expandLess : expandMore}
            alt="expand"
          />
        </div>
        {this.props.activeSubcategory && showPanel
          ? Object.values(cesiumAssets.assets).map((item, i) => {
              return (
                <ViewOptionsRow
                  key={i}
                  style={{ paddingLeft: '20px' }}
                  index={i}
                  item={item}
                  subcategory={subcategory}
                  toggleVisible={this.toggleVisible}
                  setActiveAsset={this.setActiveAsset}
                />
              );
            })
          : ''}
      </div>
    ) : (
      ''
    );
  }
}

function mapStateToProps(state) {
  return {
    activeSubcategory: state.portableState.activeSubcategory,
    dev_mode: state.notifications.dev_mode,
    chosenCategory: state.portableState.activeCategory,
    portableState: state.portableState,
  };
}

const mapDispatchToProps = dispatch => ({
  setAssetVisibility: payload => dispatch(setAssetVisibility(payload)),
  setActiveAsset: payload => dispatch(setActiveAsset(payload)),
});
export default connect(mapStateToProps, mapDispatchToProps)(ViewOptions);
